import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useProveedoresList() {
  // Use toast
  const toast = useToast()

  const refProveedorListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'id', sortable: true },
    { key: 'nombre_comercial', label: 'Nombre Comercial', sortable: true },
    { key: 'nombre_fiscal', label: 'Nombre Fiscal', sortable: true },
    { key: 'estado', label: 'Estado', sortable: true },
    { key: 'cif', label: 'CIF', sortable: true },
    { key: 'contacto', label: 'Contacto', sortable: true },
    { key: 'email', label: 'Email', sortable: true },
    { key: 'actions', label: '' },
  ]
  const perPage = ref(50)
  const totalProveedores = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refProveedorListTable.value ? refProveedorListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProveedores.value,
    }
  })

  const refetchData = () => {
    refProveedorListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchProveedores = (ctx, callback) => {
    store
      .dispatch('app-proveedor/fetchProveedores', {
        body: {
          search: searchQuery.value,
          paginate: perPage.value,
        },
        headers: {
          'X-Sort': `proveedores.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {
          paginate: true,
          page: currentPage.value,
        },
      })
      .then(res => {
        const { total } = res
        totalProveedores.value = total
        callback(res.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching proveedores list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const listProveedores = () => new Promise((resolve, reject) => {
    store
      .dispatch('app-proveedor/listProveedores', {
        body: {
          search: searchQuery.value,
        },
        headers: {
          'X-Sort': `proveedores.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {},
      })
      .then(res => {
        const { total } = res
        totalProveedores.value = total
        const filterActive = res.data.filter((el) => el.estado === 1)
        if (res.data.length > filterActive.length) {
          totalProveedores.value -= (res.data.length - filterActive.length)
        }
        resolve(filterActive || [])
      })
      .catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching proveedores list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        reject(e)
      })
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const parseDate = date => {
    let d = date.substring(0, 10)
    d = d.split('-').reverse().join('-')
    const t = date.substring(12, 19)
    return `${d} ${t}`
  }

  const resolveStatusVariant = status => {
    if (status === 0) return 'warning'
    if (status === 1) return 'success'
    if (status === 2) return 'secondary'
    return 'primary'
  }

  const resolveStatusText = status => {
    if (status === 0) return 'inactivo'
    if (status === 1) return 'activo'
    if (status === 2) return 'pendiente'
    return '-'
  }

  return {
    fetchProveedores,
    listProveedores,
    tableColumns,
    perPage,
    currentPage,
    totalProveedores,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProveedorListTable,

    refetchData,
    parseDate,
    resolveStatusVariant,
    resolveStatusText,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
