import { useModule } from '@/controllers/manager'
import { messageAlerts } from '@/middlewares/responseHandler'
import store from '@/store/index'

export default {
  namespaced: true,
  state: {
    module: 'proveedor',
    loading: false,
  },
  getters: {},
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload
    },
  },
  actions: {
    async fetchProveedores({ state, commit }, payload) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        return await controller.getAll(payload)
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return []
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async fetchProveedor({ state, commit }, payload) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        return await controller.getById(payload)
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return []
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async addProveedor({ commit, state }, proveedorData) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        await controller.create(proveedorData)
        store.commit('app/SET_ALERT', messageAlerts.a200Alert)
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async listProveedores({ state, commit }, payload) {
      commit('SET_LOADING', true)
      store.commit('app/RESET_ALERT')
      const controller = useModule(state.module)
      try {
        return await controller.listWithoutPagination(payload)
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return []
      } finally {
        commit('SET_LOADING', false)
      }
    },
  },
}
